import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export type MappedDataType<T> = { isLoaded: boolean; data: T };

interface UseMappedDataProps<T> {
  isUsingMockData: boolean;
  mapMockData: (setMappedData: Dispatch<SetStateAction<MappedDataType<T>>>) => () => void;
  mapRealData: (setMappedData: Dispatch<SetStateAction<MappedDataType<T>>>) => () => void;
}

const useMappedData = <T>(props: UseMappedDataProps<T>): MappedDataType<T> => {
  const { isUsingMockData, mapMockData, mapRealData } = props;
  const [mappedData, setMappedData] = useState<MappedDataType<T>>({ isLoaded: false, data: null });

  useEffect(() => {
    if (isUsingMockData) {
      mapMockData(setMappedData)();
      return;
    }

    mapRealData(setMappedData)();
  }, [isUsingMockData, mapMockData, mapRealData]);

  return mappedData;
};

export default useMappedData;
