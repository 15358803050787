import { useMockData } from '@hooks/useMockData';
import useMappedData from '@hooks/useMappedData';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

interface UseMapperProps<RealData, MappedData, MockData = MappedData> {
  mockDataUrl: string;
  backendData: RealData;
  isUsingMockData?: boolean;
  mockDataMapper?: (setData: (data: MappedData) => void, mockData: MockData | MappedData) => void;
  realDataMapper: (setData: (data: MappedData) => void, backendData: RealData) => void;
}

export const useMapper = <RealData, MappedData, MockData = MappedData>(
  props: UseMapperProps<RealData, MappedData, MockData>
) => {
  const {
    isUsingMockData = !!+process.env.NEXT_PUBLIC_USE_MOCK_API,
    mockDataUrl,
    backendData,
    mockDataMapper = (setMappedData, mockData) => setMappedData(mockData as MappedData),
    realDataMapper,
  } = props;

  const mockData = useMockData<MockData>(mockDataUrl);

  const mappedData = useMappedData<MappedData>({
    mapMockData: useCallback(
      (setMappedData) => () => {
        if (!mockData) return;
        mockDataMapper((data) => setMappedData({ data, isLoaded: true }), mockData);
      },
      [mockData]
    ),
    mapRealData: useCallback(
      (setMappedData) => () => {
        if (!backendData) return;
        realDataMapper((data) => setMappedData({ data, isLoaded: true }), backendData);
      },
      [backendData]
    ),
    isUsingMockData,
  });

  return {
    backendData,
    mockData,
    mappedData,
  };
};

export const useMapperV2 = <TData>(props: UseMapperV2Props<TData>): TData | null => {
  const { mockDataMapper, realDataMapper, bypassMapper, data } = props;

  const [mappedData, setMappedData] = useState<TData | null>(null);

  useEffect(() => {
    if (bypassMapper) {
      return setMappedData(data as TData);
    }

    if (process.env.NEXT_PUBLIC_USE_MOCK_API === 'true') {
      return mockDataMapper?.(setMappedData);
    }

    realDataMapper?.(setMappedData);

    // eslint-disable-next-line
  }, [data, bypassMapper]);

  return mappedData;
};

interface UseMapperV2Props<TData> {
  data: unknown;
  mockDataMapper: (setData: Dispatch<SetStateAction<TData | null>>) => void;
  realDataMapper: (setData: Dispatch<SetStateAction<TData | null>>) => void;
  bypassMapper?: boolean;
}

export default useMapper;
