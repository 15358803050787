import { PropsWithChildren, useState, useEffect } from 'react';
import Link from 'next/link';
import { addDomainTo } from '@utils/functions/addDomainTo';
import { LinkChildrenProps } from '@atoms/LinkChildren/LinkChildren.props';

const LinkChildrenAsync = (props: PropsWithChildren<LinkChildrenProps>) => {
  const { isExternal, target = '_self', children, rel, className = '', asyncUrl } = props;

  if (isExternal) {
    return (
      <a href={asyncUrl} target={target} rel={rel} className={className}>
        {children}
      </a>
    );
  }

  return (
    <Link href={asyncUrl} rel={rel} className={className} prefetch={false}>
      {children}
    </Link>
  );
};

export default LinkChildrenAsync;
