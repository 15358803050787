import { AnchorHTMLAttributes, IframeHTMLAttributes, ImgHTMLAttributes } from 'react';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import theme from '@mui/theme';
import Box from '@mui/material/Box';
import { DOMNode, domToReact, HTMLReactParserOptions } from 'html-react-parser';
import Typography from '@mui/material/Typography';
import CustomButton from '@atoms/CustomButton/CustomButton';
import TableParagraph from '@organisms/TableParagraph/TableParagraph';

import { parseHTML } from '@utils/functions/parseHTML';
import { addDomainTo } from '@utils/functions/addDomainTo';
import { ButtonColor } from '@model/types';

const Icon = dynamic(() => import('@mui/material/Icon'));
const IconButton = dynamic(() => import('@mui/material/IconButton'));

export interface RichTextProps {
  text: string;
}
const RichText = ({ text }: RichTextProps) => {
  text = text.replace(/<p><iframe/g, '<iframe').replace(/<\/iframe><\/p>/g, '</iframe>');
  return <>{parseHTML(text, textOptions)}</>;
};

export default RichText;

const textOptions: HTMLReactParserOptions = {
  replace: (domNode: Extract<DOMNode, { children } | { type: 'text' }>) => {
    if (domNode.type === 'text') {
      const { parent } = domNode;

      if (parent?.type === 'tag' && parent?.name === 'li') {
        return (
          <Typography component="span" variant="bodyL">
            {domNode.data}
          </Typography>
        );
      }

      return null;
    }

    const { name, attribs, children } = domNode;

    switch (name) {
      case 'br':
        return <br />;

      case 'h2':
        return (
          <Typography mt={theme.spacing(64)} mb={theme.spacing(24)} variant="h2" component="h2">
            {domToReact(children, textOptions)}
          </Typography>
        );

      case 'table': {
        return domNode;

        // TODO: Da fixare la generazione delle tabelle
        const node = domNode as any;

        const theadElement = node.children.find((child) => child.name === 'thead');
        const hasTHead = theadElement !== undefined;

        const rows =
          node.children
            .find((child) => child.name === 'tbody')
            ?.children.map((row) => {
              const renderCell = (index) => {
                const cell = row.children?.[index]?.children[0];

                if (cell.type === 'text') return cell.data;

                if (cell.name === 'a') {
                  const attributes = cell.attributes.reduce(
                    (acc, curr) => ({
                      ...acc,
                      [curr.name]: curr.value,
                    }),
                    {}
                  );

                  if (attributes.href.includes('.pdf')) {
                    return (
                      <IconButton
                        component="a"
                        download
                        sx={{ '& svg': { fontSize: theme.spacing(32) } }}
                        {...attributes}
                        aria-label="download pdf"
                      >
                        <Icon sx={{ color: 'primary.main' }} baseClassName="material-icons-outlined">
                          picture_as_pdf
                        </Icon>
                      </IconButton>
                    );
                  }

                  return (
                    <CustomButton
                      component="a"
                      variant="textLink"
                      color="textLink"
                      sx={{ verticalAlign: 'baseline' }}
                      text={cell.children?.[0]?.data}
                      {...attributes}
                    />
                  );
                }

                return '';
              };

              const cells = row.children
                .filter((child) => child.name === 'td' || child.name === 'th')
                .map((cell, index) => renderCell(index));

              const rowData = {};
              cells.forEach((cell, index) => {
                rowData[`column-${index}`] = cell;
              });

              return rowData;
            }) || [];

        const tableHeaders = hasTHead
          ? theadElement.children[0].children.map((th) => domToReact(th.children, textOptions))
          : [];

        const caption = node.children.find((child) => child.name === 'caption')?.children[0]?.data || '';

        const fieldParagraph = {
          columns: tableHeaders.map((header, index) => ({
            id: `column-${index}`,
            align: 'left',
            minWidth: 150,
            label: header,
          })),
          caption,
          rows,
        };

        return <TableParagraph isRichTextTable fieldParagraph={fieldParagraph} />;
      }

      case 'h3':
        return (
          <Typography mt={theme.spacing(64)} mb={theme.spacing(24)} variant="h3" component="h3">
            {domToReact(children, textOptions)}
          </Typography>
        );

      case 'h4':
        return (
          <Typography mt={theme.spacing(64)} mb={theme.spacing(24)} variant="h4" component="h4">
            {domToReact(children, textOptions)}
          </Typography>
        );

      case 'h5':
        return (
          <Typography mt={theme.spacing(64)} mb={theme.spacing(24)} variant="h5" component="h5">
            {domToReact(children, textOptions)}
          </Typography>
        );

      case 'h6':
        return (
          <Typography mt={theme.spacing(64)} mb={theme.spacing(24)} variant="h6" component="h6">
            {domToReact(children, textOptions)}
          </Typography>
        );

      case 'p':
        return (
          <Typography
            variant={attribs && attribs.class === 'subtitle' ? 'subtitle' : 'bodyL'}
            component="p"
            sx={{
              '&:not(:last-of-type)': {
                mb: theme.spacing(24),
              },
            }}
          >
            {domToReact(children, textOptions)}
          </Typography>
        );

      case 'i':
      case 'em':
        return (
          <Typography component="em" fontStyle="italic" fontSize="inherit">
            {domToReact(children, textOptions)}
          </Typography>
        );

      case 'b':
      case 'strong':
        return (
          <Typography component="strong" fontWeight={700} fontSize="inherit">
            {domToReact(children, textOptions)}
          </Typography>
        );

      case 'ul':
        return (
          <Box component="ul" pl={theme.spacing(20)} my={theme.spacing(40)}>
            {domToReact(children, textOptions)}
          </Box>
        );

      case 'ol':
        return (
          <Box component="ol" pl={theme.spacing(20)} my={theme.spacing(40)}>
            {domToReact(children, textOptions)}
          </Box>
        );

      case 'li':
        return <li>{domToReact(children, textOptions)}</li>;

      case 'iframe':
        return (
          <Box
            component="iframe"
            border="none"
            loading="lazy"
            {...(attribs as IframeHTMLAttributes<Element>)}
            style={{ width: '100%', border: 0, minHeight: theme.spacing(600) }}
          />
        );

      case 'a': {
        const { href, target, rel } = attribs as AnchorHTMLAttributes<Element>;
        const isOutlineCta = attribs?.class === 'rich-text--cta-outline';
        const isFilledCta = attribs?.class === 'rich-text--cta-filled';

        if (isOutlineCta || isFilledCta) {
          const ctaSx = {
            display: 'block',
            width: 'fit-content',
            height: 'fit-content',
            marginY: '1rem',
            verticalAlign: 'baseline',
            fontSize: 'inherit',
            textDecoration: 'none',
            border: '0.19rem solid #DB002F',
            ...(isFilledCta && { bgColor: '#DB002F' }),
            '&:hover': {
              textDecoration: 'none',
              borderColor: '#c0001a',
              ...(isFilledCta && { bgColor: '#c0001a' }),
            },

            '& span.buttonText': {
              color: isFilledCta ? '#fff' : 'inherit',
            },
          };

          const ctaColor = isFilledCta ? 'primary' : 'textLink';

          return (
            <CustomButton
              component="a"
              variant="small"
              color={ctaColor}
              sx={ctaSx}
              rel={rel}
              href={href}
              target={target}
              text={domToReact(children, textOptions)}
            />
          );
        }

        return (
          <CustomButton
            component="a"
            variant="textLink"
            color="textLink"
            sx={{ verticalAlign: 'baseline', fontSize: 'inherit' }}
            rel={rel}
            href={href}
            target={target}
            text={domToReact(children, textOptions)}
          />
        );
      }

      case 'img':
        const { src, alt, width = null, height = null } = attribs as ImgHTMLAttributes<Element>;
        const fill: boolean = !width && !height;

        return (
          <Box
            component="span"
            sx={{
              height: fill ? theme.spacing(570) : 'auto',
              position: 'relative',
              display: 'block',
              my: theme.spacing(24),
            }}
          >
            <Image
              src={addDomainTo(src)}
              alt={alt}
              {...(fill
                ? {
                    fill: true,
                    style: { objectFit: 'contain' },
                  }
                : {
                    width: +width,
                    height: +height,
                    style: { maxWidth: '100%', height: 'auto' },
                  })}
            />
          </Box>
        );

      default:
        return null;
    }
  },
};
