import { useEffect, useState } from 'react';
import axios from 'axios';

export const useMockData = <T>(url: string) => {
  const [mockData, setMockData] = useState<T>(null);

  useEffect(() => {
    if (!+process.env.NEXT_PUBLIC_USE_MOCK_API) return;

    const getData = async () => {
      const response = await axios.get(url);
      return response.data;
    };

    getData().then((data) => setMockData(data));
  }, []);

  return mockData;
};
