import dynamic from 'next/dynamic';
import voca from 'voca';
import theme from '@mui/theme';
import Typography from '@mui/material/Typography';
import LinkChildrenAsync from '@atoms/LinkChildrenAsync/LinkChildrenAsync';
import GridContainer from '@atoms/GridContainer/GridContainer';
import { TableParagraphProps } from '@organisms/TableParagraph/TableParagraph.props';
import { TableParagraphModel } from '@model/table.model';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useMapper } from '@hooks/useMapper';
import { addDomainTo } from '@utils/functions/addDomainTo';
import styles from './TableParagraph.module.scss';

const Paper = dynamic(() => import('@mui/material/Paper'));
const Table = dynamic(() => import('@mui/material/Table'));
const TableBody = dynamic(() => import('@mui/material/TableBody'));
const TableCell = dynamic(() => import('@mui/material/TableCell'));
const TableContainer = dynamic(() => import('@mui/material/TableContainer'));
const TableHead = dynamic(() => import('@mui/material/TableHead'));
const TableRow = dynamic(() => import('@mui/material/TableRow'));

/** Table Rows */
interface FieldTableRow {
  /**
   * Row position, stringed number
   * @RowOrder From bottom to top
   * @TableHeader The lowest number marks the table-header
   */
  weight: string;
  [key: number | string]: string;
}

interface FieldTableValue {
  [key: number]: FieldTableRow;
  caption: string;
}

interface FieldTable {
  caption: string;
  format: any | null;
  value: FieldTableValue;
}

const getRowsAndColumnsFromFieldTable = (field_table: FieldTable) => {
  if (!field_table?.value) return { rows: [], columns: [] };

  const getRowsWithoutCaption = () => {
    const { value } = field_table;
    const { caption = '', ...fields } = value;

    return fields;
  };

  const getColumnId = (
    header: string,
    i: number
    // header can be empty
  ) => (header ? voca.kebabCase(header) : `generated_${i}`);

  const cleanedRows = getRowsWithoutCaption();

  const [{ weight, ...headers }, ...bodyRows] = Object.values(cleanedRows);

  const columns =
    Object.values(headers).map(
      (header, i) =>
        ({
          align: 'left',
          id: getColumnId(header, i),
          format: 'text',
          label: header || '',
          minWidth: 100,
        } as const)
    ) || [];

  const rows = Object.values(bodyRows)
    .sort((rowA, rowB) => (Number(rowA.weight) > Number(rowB.weight) ? 1 : -1))
    .map((row) => {
      const { weight, ...fields } = row;
      const arrRow = Object.values(fields);

      return arrRow;
    })
    .reduce((mappedRows: { [key: string]: string }[], row) => {
      const keys = Object.values(headers);
      if (row.length !== keys.length) return mappedRows;

      const mappedRow = keys.reduce(
        (acc, k, i) => ({
          ...acc,
          [getColumnId(k, i)]: row[i],
        }),
        {}
      );

      return [...mappedRows, mappedRow];
    }, []);

  return { rows, columns };
};

const TableParagraph = (props: TableParagraphProps) => {
  const { fieldParagraph, isRichTextTable = false } = props;
  const { isSmaller: isMobile } = useBreakpoint('lg');

  const firstRowStickyMob: boolean =
    !!fieldParagraph?.behavior_settings?.style_options?.table_sticky?.css_class['row-mobile'] || true;
  const firstColumnStickyMob: boolean =
    !!fieldParagraph?.behavior_settings?.style_options?.table_sticky?.css_class['column-mobile'] || true;

  const {
    mappedData: { data, isLoaded },
  } = useMapper<any, TableParagraphModel>({
    backendData: fieldParagraph,
    mockDataUrl: fieldParagraph.mockDataUrl || '/data/table/table-paragraphs.json',
    realDataMapper: (setData, backendData) => {
      if (isRichTextTable) {
        return setData(backendData);
      }

      const { field_table }: { field_table: FieldTable } = backendData.hasOwnProperty('field_table')
        ? backendData
        : backendData['field_item'] || {};

      const { rows, columns } = getRowsAndColumnsFromFieldTable(field_table);

      setData({
        caption: field_table?.caption,
        columns,
        //todo titolo non arriva da BE
        title: '',
        rows,
      });
    },
  });

  if (!isLoaded) {
    return null;
  }

  const { columns, title, rows, caption } = data;

  return (
    <GridContainer>
      <Paper
        sx={{
          py: theme.spacing(24),
          px: {
            xs: theme.spacing(24),
            lg: theme.spacing(32),
          },
          background: 'linear-gradient(180deg, #D9E2E4 0%, rgba(136, 162, 170, 0.1) 100%)',
          width: '100%',
          overflow: 'hidden',
          borderRadius: theme.spacing(16),
        }}
      >
        {title && (
          <Typography mb={{ xs: theme.spacing(16), lg: theme.spacing(24) }} variant="h5">
            {title}
          </Typography>
        )}
        <TableContainer
          sx={{
            borderRadius: theme.spacing(8, 8, 0, 8),
            maxHeight: {
              lg: theme.spacing(568),
              xs: '65vh',
            },
            '&::-webkit-scrollbar': {
              width: theme.spacing(3),
              height: theme.spacing(3),
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.grey[500],
              borderRadius: '10px',
            },
          }}
        >
          <Table
            {...(!isMobile && {
              stickyHeader: true,
            })}
            {...(firstRowStickyMob &&
              isMobile && {
                stickyHeader: true,
              })}
          >
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      backgroundColor: 'common.white',
                      borderBottom: '.1rem solid',
                      borderColor: 'secondary.300',
                      p: {
                        xs: theme.spacing(16, 16),
                        lg: theme.spacing(21, 24),
                      },
                      ...(!isMobile &&
                        index === 0 && {
                          position: 'sticky',
                          left: 0,
                          zIndex: 99,
                        }),

                      ...(isMobile &&
                        firstColumnStickyMob &&
                        index === 0 && {
                          position: 'sticky',
                          left: 0,
                          zIndex: 99,
                        }),
                    }}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography variant="bodySBold" component="span">
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  sx={{
                    '&:nth-of-type(2n-1)': {
                      '&, &:hover': {
                        backgroundColor: 'common.white',
                      },
                    },

                    '& .MuiTableCell-root:first-of-type': {
                      backdropFilter: `blur(${theme.spacing(10)})`,

                      '@-moz-document url-prefix()': {
                        backgroundColor: 'rgba(255, 255, 255, .9)',
                      },
                    },

                    '&:nth-of-type(2n)': {
                      '&, &:hover': {
                        backgroundColor: 'alphaWhite.60',
                      },
                    },
                  }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                >
                  {columns.map((column, index) => {
                    const content: string = row[column.id];
                    const isLink: boolean = content?.includes('://');

                    return (
                      <TableCell
                        sx={{
                          minWidth: {
                            xs: isLink ? 100 : content.length >= 100 ? 300 : 150,
                            lg: 150,
                          },
                          borderBottom: 'none',
                          p: {
                            xs: theme.spacing(12, 12),
                            lg: theme.spacing(21, 24),
                          },
                          ...(!isMobile &&
                            index === 0 && {
                              position: 'sticky',
                              left: 0,
                            }),

                          ...(isMobile &&
                            firstColumnStickyMob &&
                            index === 0 && {
                              position: 'sticky',
                              left: 0,
                            }),
                        }}
                        key={column.id}
                        align={column.align}
                      >
                        {isLink ? (
                          <LinkChildrenAsync isExternal={true} target="_blank" asyncUrl={addDomainTo(content)}>
                            <Typography variant="bodyL">{content.endsWith('.pdf') ? 'PDF' : 'LINK'}</Typography>
                          </LinkChildrenAsync>
                        ) : (
                          <Typography
                            className={styles.typoCell}
                            variant={column.format === 'number' ? 'bodySMono' : 'bodyS'}
                            component="span"
                            dangerouslySetInnerHTML={{ __html: content }}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {caption && (
          <Typography
            component="small"
            display="inline-block"
            mt={{ xs: theme.spacing(16), lg: theme.spacing(24) }}
            variant="caption"
          >
            {caption}
          </Typography>
        )}
      </Paper>
    </GridContainer>
  );
};

export default TableParagraph;
